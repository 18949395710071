import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../../styles/core.css"
import "../../../styles/styles.css"
import Layout from "../../../components/Layout"
import MenuOne from "../../../components/MenuOne"
import MenuTwo from "../../../components/MenuTwo"
import MenuThree from "../../../components/MenuThree"
import Content from "../../../components/Content"
import Gallery from "../../../components/Gallery"

const galImgs = ['/page-images/00006_final_0.jpg', '/page-images/00005_final_0.jpg', '/page-images/00004_rj.jpg', '/page-images/00012_final.jpg', '/page-images/101nikon-dscn1002_dscn1002.jpg', '/page-images/00007_final_0.jpg', '/page-images/00010_final.jpg', '/page-images/raffrey_halls_stair.jpg', '/page-images/raffrey_halls_minor_hall.jpg']

export default function OurWork() {
  return (
    <Layout breadcrumbs={["Raffrey Church Halls, Crossgar"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>
<p>A major extension to the existing church halls. The new suite consists of multi-use facilities including assembley rooms and creche facilities. The growing requirements of youth organisations and the general needs of a busy congregation are solved in this innovative solution.</p>
</Content>

      <div class="region region-content-bottom">
        <MenuOne active='our-work' />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
        <MenuThree
          items={[
            '/our-work/community/raffrey-church-halls-crossgar',

          ]} />
      </div>

  </Layout>

  )
}
